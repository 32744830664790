<template>
  <div
    class="container"
    v-if="this.generalReportData.length && !loading"
    :style="dataStyles"
  >
    <custom-button
      variant="button"
      download="reporte_general.csv"
      :href="downloadLink"
    >
      Excel
      <font-awesome-icon :icon="icons.excel" slot="icon" />
    </custom-button>
    <div class="general-report-table">
      <div class="header row">
        <span class="header-column-title">Dimension</span>
        <span class="header-column-title">Pregunta</span>
        <span class="header-column-title">Promedio</span>
        <template v-for="category in cat">
          <div class="header-column-title header-categories" :key="category.id">
            <span>{{ category.name }}</span>
            <div class="category-options">
              <template v-for="option in category.options">
                <span :key="option.name">{{ option.name }}</span>
              </template>
            </div>
          </div>
        </template>
      </div>

      <div class="content" v-if="generalReportData.length">
        <div class="dimension row">
          <span></span>
          <div class="average row">
            <span>Número de respuestas</span>
            <span>{{ generalReportData[3].total }}</span>
            <template v-for="category in cat">
              <div class="option" :key="category.name">
                <template v-for="option in category.options">
                  <span class="option-value" :key="option.name">
                    {{ option.name }}
                    <span>
                      {{
                        generalReportData[3][category.name] &&
                        generalReportData[3][category.name][option.name]
                          ? generalReportData[3][category.name][option.name]
                          : 0
                      }}
                    </span>
                  </span>
                </template>
              </div>
            </template>
          </div>
        </div>

        <div
          class="dimension row"
          v-for="dimension in Object.keys(generalReportData[0])"
          :key="dimension"
        >
          <span>{{ dimension }}</span>
          <div class="questions-row">
            <template
              v-for="question in Object.keys(
                generalReportData[0][dimension]
              ).filter(question => question !== 'satisfaction')"
            >
              <div class="question row" :key="question">
                <span>
                  {{ question }}
                </span>
                <span>
                  {{
                    generalReportData[0][dimension][
                      question
                    ].satisfaction.toFixed(0)
                  }}%
                </span>

                <template v-for="category in cat">
                  <div class="option" :key="category.name + question">
                    <template v-for="option in category.options">
                      <span class="option-value" :key="option.name">
                        {{ option.name }}
                        <span>
                          {{
                            generalReportData[0][dimension][question][
                              category.name
                            ][option.name]
                              ? generalReportData[0][dimension][question][
                                  category.name
                                ][option.name].satisfaction.toFixed(0)
                              : 0
                          }}%
                        </span>
                      </span>
                    </template>
                  </div>
                </template>
              </div>
            </template>

            <div class="average row">
              <span>Promedio</span>
              <span>
                {{ generalReportData[0][dimension].satisfaction.toFixed(0) }}%
              </span>
              <template v-for="category in cat">
                <div class="option" :key="category.name">
                  <template v-for="option in category.options">
                    <span class="option-value" :key="option.name">
                      {{ option.name }}
                      <span>
                        {{
                          generalReportData[1][dimension] &&
                          generalReportData[1][dimension][category.name][
                            option.name
                          ]
                            ? generalReportData[1][dimension][category.name][
                                option.name
                              ].toFixed(0)
                            : 0
                        }}%
                      </span>
                    </span>
                  </template>
                </div>
              </template>
            </div>
          </div>
        </div>

        <div class="dimension row">
          <span></span>
          <div class="average row">
            <span>Promedio General</span>
            <span>{{ generalReportData[2].satisfaction.toFixed(0) }}%</span>
            <template v-for="category in cat">
              <div class="option" :key="category.name">
                <template v-for="option in category.options">
                  <span class="option-value" :key="option.name">
                    {{ option.name }}
                    <span>
                      {{
                        generalReportData[2][category.name][option.name]
                          ? generalReportData[2][category.name][
                              option.name
                            ].toFixed(0)
                          : 0
                      }}%
                    </span>
                  </span>
                </template>
              </div>
            </template>
          </div>
        </div>

        <div v-if="selectedSurvey.isEnpsEnabled" class="dimension row">
          <span>Medición eNPS</span>
          <div class="questions-row">
            <template v-for="cont in 11">
              <div class="question row" :key="cont - 1">
                <span>
                  {{ cont - 1 }}
                </span>
                <span>
                  {{ generalReportData[5][cont - 1] }}
                </span>

                <template v-for="category in cat">
                  <div class="option" :key="category.name + cont - 1">
                    <template v-for="option in category.options">
                      <span class="option-value" :key="option.name">
                        {{ option.name }}
                        <span>
                          {{
                            generalReportData[4][category.name][option.name] &&
                            generalReportData[4][category.name][option.name][
                              cont - 1
                            ]
                              ? (
                                  (generalReportData[4][category.name][
                                    option.name
                                  ][cont - 1] /
                                    generalReportData[3][category.name][
                                      option.name
                                    ]) *
                                  100
                                ).toFixed(0)
                              : 0
                          }}%
                        </span>
                      </span>
                    </template>
                  </div>
                </template>
              </div>
            </template>

            <!-- <div class="average row">
              <span>Promedio</span>
              <span>
                {{ generalReportData[0][dimension].satisfaction.toFixed(0) }}%
              </span>
              <template v-for="category in cat">
                <div class="option" :key="category.name">
                  <template v-for="option in category.options">
                    <span class="option-value" :key="option.name">
                      {{ option.name }}
                      <span>
                        {{
                          generalReportData[1][dimension] &&
                          generalReportData[1][dimension][category.name][
                            option.name
                          ]
                            ? generalReportData[1][dimension][category.name][
                                option.name
                              ].toFixed(0)
                            : 0
                        }}%
                      </span>
                    </span>
                  </template>
                </div>
              </template>
            </div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="loading-screen" v-else-if="loading"><loading-screen /></div>
</template>

<script>
import { mapActions, mapState, mapMutations } from "vuex";
import CustomButton from "../components/CustomButton.vue";
import LoadingScreen from "../components/LoadingScreen.vue";
import { faFileExcel } from "@fortawesome/free-solid-svg-icons";

export default {
  components: {
    CustomButton,
    LoadingScreen
  },
  data() {
    return {
      loading: false,
      generalReportData: [],
      icons: {
        excel: faFileExcel
      }
    };
  },
  methods: {
    ...mapActions([
      "fetchAllAnswers",
      "getGeneralReportData",
      "fetchAllSurveys"
    ]),
    ...mapActions("filters", ["fetchFilters"]),
    ...mapActions("scale", ["fetchScale"]),

    ...mapMutations(["setCurrentSurvey"]),
    // getReport() {
    //   console.log(this.answers);
    //   let data1 = {};
    //   let participation = { total: this.answers.length };
    //   let enps = {};
    //   let enpsTotal = {
    //     0: 0,
    //     1: 0,
    //     2: 0,
    //     3: 0,
    //     4: 0,
    //     5: 0,
    //     6: 0,
    //     7: 0,
    //     8: 0,
    //     9: 0,
    //     10: 0,
    //   };
    //   this.answers.forEach((answer) => {
    //     enpsTotal[answer.enpsAnswer.answer] += 1;
    //     Object.keys(answer.features).forEach((feature) => {
    //       if (!participation[feature]) participation[feature] = {};
    //       if (!participation[feature][answer.features[feature]])
    //         participation[feature][answer.features[feature]] = 1;
    //       else participation[feature][answer.features[feature]] += 1;

    //       if (!enps[feature]) enps[feature] = {};
    //       if (!enps[feature][answer.features[feature]])
    //         enps[feature][answer.features[feature]] = {};
    //       if (
    //         !enps[feature][answer.features[feature]][answer.enpsAnswer.answer]
    //       )
    //         enps[feature][answer.features[feature]][
    //           answer.enpsAnswer.answer
    //         ] = 1;
    //       else
    //         enps[feature][answer.features[feature]][
    //           answer.enpsAnswer.answer
    //         ] += 1;
    //     });

    //     [...answer.multipleChoiceAnswers, ...answer.engagementAnswers].forEach(
    //       (mcAnswer) => {
    //         if (mcAnswer.answer.text) {
    //           let value = this.scale.find(
    //             (scale) => scale.text === mcAnswer.answer.text
    //           ).isPositive
    //             ? 1
    //             : 0;
    //           Object.keys(answer.features).forEach((feature) => {
    //             if (!data1[mcAnswer.section.name])
    //               data1[mcAnswer.section.name] = {};
    //             if (!data1[mcAnswer.section.name][mcAnswer.question.text])
    //               data1[mcAnswer.section.name][mcAnswer.question.text] = {};
    //             if (
    //               !data1[mcAnswer.section.name][mcAnswer.question.text][feature]
    //             )
    //               data1[mcAnswer.section.name][mcAnswer.question.text][
    //                 feature
    //               ] = {};
    //             if (
    //               !data1[mcAnswer.section.name][mcAnswer.question.text][
    //                 feature
    //               ][answer.features[feature]]
    //             )
    //               data1[mcAnswer.section.name][mcAnswer.question.text][feature][
    //                 answer.features[feature]
    //               ] = {};

    //             data1[mcAnswer.section.name][mcAnswer.question.text][feature][
    //               answer.features[feature]
    //             ].total = data1[mcAnswer.section.name][mcAnswer.question.text][
    //               feature
    //             ][answer.features[feature]].total
    //               ? data1[mcAnswer.section.name][mcAnswer.question.text][
    //                   feature
    //                 ][answer.features[feature]].total + 1
    //               : 1;

    //             data1[mcAnswer.section.name][mcAnswer.question.text][feature][
    //               answer.features[feature]
    //             ].positive = data1[mcAnswer.section.name][
    //               mcAnswer.question.text
    //             ][feature][answer.features[feature]].positive
    //               ? data1[mcAnswer.section.name][mcAnswer.question.text][
    //                   feature
    //                 ][answer.features[feature]].positive + value
    //               : value;
    //           });
    //         }
    //       }
    //     );
    //   });

    //   Object.keys(data1).forEach((dimension) => {
    //     let dimensionCount = 0;
    //     let dimensionAcc = 0;
    //     Object.keys(data1[dimension]).forEach((question) => {
    //       let questionPositive = 0;
    //       let questionTotal = 0;
    //       Object.keys(data1[dimension][question]).forEach((feature) => {
    //         let featureCount = 0;
    //         let featureAcc = 0;
    //         Object.keys(data1[dimension][question][feature]).forEach(
    //           (option) => {
    //             data1[dimension][question][feature][option].satisfaction =
    //               (data1[dimension][question][feature][option].positive /
    //                 data1[dimension][question][feature][option].total) *
    //               100;
    //             questionPositive +=
    //               data1[dimension][question][feature][option].positive;
    //             questionTotal +=
    //               data1[dimension][question][feature][option].total;
    //             featureCount += 1;
    //             featureAcc +=
    //               data1[dimension][question][feature][option].satisfaction;
    //           }
    //         );
    //         data1[dimension][question][feature].satisfaction =
    //           featureAcc / featureCount;
    //       });
    //       data1[dimension][question].satisfaction =
    //         (questionPositive / questionTotal) * 100;
    //       dimensionCount += 1;
    //       dimensionAcc += data1[dimension][question].satisfaction;
    //     });
    //     data1[dimension].satisfaction = dimensionAcc / dimensionCount;
    //   });

    //   let data2 = {};
    //   Object.keys(data1).forEach((dimension) => {
    //     data2[dimension] = {};
    //     Object.keys(data1[dimension]).forEach((question) => {
    //       Object.keys(data1[dimension][question]).forEach((feature) => {
    //         if (!data2[dimension][feature]) data2[dimension][feature] = {};
    //         Object.keys(data1[dimension][question][feature]).forEach(
    //           (option) => {
    //             if (!data2[dimension][feature][option])
    //               data2[dimension][feature][option] = 0;
    //             data2[dimension][feature][option] +=
    //               data1[dimension][question][feature][option].satisfaction || 0;
    //           }
    //         );
    //       });
    //     });
    //   });

    //   Object.keys(data2).forEach((dimension) => {
    //     Object.keys(data2[dimension]).forEach((feature) => {
    //       Object.keys(data2[dimension][feature]).forEach((option) => {
    //         data2[dimension][feature][option] =
    //           data2[dimension][feature][option] /
    //           (Object.keys(data1[dimension]).length - 1);
    //       });
    //     });
    //   });

    //   let data3 = {};
    //   let dimensionCount = 0;
    //   let dimensionAcc = 0;
    //   Object.keys(data2).forEach((dimension) => {
    //     Object.keys(data2[dimension])
    //       .filter((feature) => feature !== "satisfaction")
    //       .forEach((feature) => {
    //         if (!data3[feature]) data3[feature] = {};
    //         Object.keys(data2[dimension][feature]).forEach((option) => {
    //           if (!data3[feature][option])
    //             data3[feature][option] = { acc: 0, total: 0 };
    //           dimensionAcc += data2[dimension][feature][option];
    //           dimensionCount += 1;
    //           data3[feature][option].acc += data2[dimension][feature][option];
    //           data3[feature][option].total += 1;
    //         });
    //       });
    //   });
    //   Object.keys(data3).forEach((feature) => {
    //     Object.keys(data3[feature]).forEach((option) => {
    //       data3[feature][option] =
    //         data3[feature][option].acc / data3[feature][option].total;
    //     });
    //   });
    //   data3.satisfaction = dimensionAcc / dimensionCount;
    //   this.generalReportData = [
    //     data1,
    //     data2,
    //     data3,
    //     participation,
    //     enps,
    //     enpsTotal,
    //   ];
    // },

    CSVtoArray(text) {
      // var re_valid = /^\s*(?:'[^'\\]*(?:\\[\S\s][^'\\]*)*'|"[^"\\]*(?:\\[\S\s][^"\\]*)*"|[^,'"\s\\]*(?:\s+[^,'"\s\\]+)*)\s*(?:,\s*(?:'[^'\\]*(?:\\[\S\s][^'\\]*)*'|"[^"\\]*(?:\\[\S\s][^"\\]*)*"|[^,'"\s\\]*(?:\s+[^,'"\s\\]+)*)\s*)*$/;
      // var re_value = /(?!\s*$)\s*(?:'([^'\\]*(?:\\[\S\s][^'\\]*)*)'|"([^"\\]*(?:\\[\S\s][^"\\]*)*)"|([^,'"\s\\]*(?:\s+[^,'"\s\\]+)*))\s*(?:,|$)/g;
      // // Return NULL if input string is not well formed CSV string.
      // if (!re_valid.test(text)) return null;
      // var a = []; // Initialize array to receive values.
      // text.replace(
      //   re_value, // "Walk" the string using replace with callback.
      //   function(m0, m1, m2, m3) {
      //     // Remove backslash from \' in single quoted values.
      //     if (m1 !== undefined) a.push(m1.replace(/\\'/g, "'"));
      //     // Remove backslash from \" in double quoted values.
      //     else if (m2 !== undefined) a.push(m2.replace(/\\"/g, '"'));
      //     else if (m3 !== undefined) a.push(m3);
      //     return ""; // Return empty string.
      //   }
      // );
      // // Handle special case of empty last value.
      // if (/,\s*$/.test(text)) a.push("");
      // return a.join(" ");
      return text.replace(/,/g, "").replace(/(\r\n|\n|\r)/gm, "");
    },

    getReportCSV() {
      let csv = [];
      csv.push("", "", "");
      this.cat.forEach(category => {
        csv.push(category.name);
        category.options.forEach((_, index) => {
          if (index !== 0) {
            csv.push("");
          }
        });
      });

      csv.push(["\n Dimension", "Pregunta", "Promedio"]);

      this.cat.forEach(category =>
        category.options.forEach(option => csv.push(option.name))
      );

      csv.push("\n", "Número de respuestas", this.generalReportData[3].total);

      this.cat.forEach(category => {
        category.options.forEach(option => {
          csv.push(
            this.generalReportData[3][category.name] &&
              this.generalReportData[3][category.name][option.name]
              ? this.generalReportData[3][category.name][option.name]
              : 0
          );
        });
      });

      Object.keys(this.generalReportData[0]).forEach(dimension => {
        Object.keys(this.generalReportData[0][dimension])
          .filter(question => question !== "satisfaction")
          .forEach((question, index) => {
            csv.push(
              index === 0 ? "\n" + dimension : "\n",
              this.CSVtoArray(question),
              this.generalReportData[0][dimension][
                question
              ].satisfaction.toFixed(0) + "%"
            );
            this.cat.forEach(category => {
              category.options.forEach(option => {
                csv.push(
                  (this.generalReportData[0][dimension][question][
                    category.name
                  ][option.name]
                    ? this.generalReportData[0][dimension][question][
                        category.name
                      ][option.name].satisfaction.toFixed(0)
                    : 0) + "%"
                );
              });
            });
          });

        csv.push(
          "\n",
          "Promedio",
          this.generalReportData[0][dimension].satisfaction.toFixed(0) + "%"
        );
        this.cat.forEach(category => {
          category.options.forEach(option => {
            csv.push(
              (this.generalReportData[1][dimension] &&
              this.generalReportData[1][dimension][category.name][option.name]
                ? this.generalReportData[1][dimension][category.name][
                    option.name
                  ].toFixed(0)
                : 0) + "%"
            );
          });
        });
      });

      csv.push(
        "\n",
        "Promedio General",
        this.generalReportData[2].satisfaction.toFixed(0) + "%"
      );

      this.cat.forEach(category => {
        category.options.forEach(option => {
          csv.push(
            (this.generalReportData[2][category.name][option.name]
              ? this.generalReportData[2][category.name][option.name].toFixed(0)
              : 0) + "%"
          );
        });
      });

      if (this.selectedSurvey.isEnpsEnabled) {
        for (let i = 0; i <= 10; i++) {
          csv.push(
            i === 0 ? "\n" + "Medición eNPS" : "\n",
            i,
            this.generalReportData[5][i]
          );
          this.cat.forEach(category => {
            category.options.forEach(option => {
              csv.push(
                (this.generalReportData[4][category.name][option.name] &&
                this.generalReportData[4][category.name][option.name][i]
                  ? (
                      (this.generalReportData[4][category.name][option.name][
                        i
                      ] /
                        this.generalReportData[3][category.name][option.name]) *
                      100
                    ).toFixed(0)
                  : 0) + "%"
              );
            });
          });
        }
      }

      return csv;
    },

    async getReportData() {
      let data1 = {};
      let participation = {};
      let enps = {};
      let enpsTotal = {};
      if (!this.loading) this.loading = true;
      try {
        let data = await this.getGeneralReportData({
          surveyId: this.selectedSurvey.id
        });
        data1 = data[0];
        participation = data[1];
        enps = data[2];
        enpsTotal = data[3];
      } catch (error) {
        console.log("Error getGeneralReportData cloud function: " + error);
      }

      this.loading = false;
      let data2 = {};
      Object.keys(data1).forEach(dimension => {
        data2[dimension] = {};
        Object.keys(data1[dimension]).forEach(question => {
          Object.keys(data1[dimension][question]).forEach(feature => {
            if (!data2[dimension][feature]) data2[dimension][feature] = {};
            Object.keys(data1[dimension][question][feature]).forEach(option => {
              if (!data2[dimension][feature][option])
                data2[dimension][feature][option] = 0;
              data2[dimension][feature][option] +=
                data1[dimension][question][feature][option].satisfaction || 0;
            });
          });
        });
      });

      Object.keys(data2).forEach(dimension => {
        Object.keys(data2[dimension]).forEach(feature => {
          Object.keys(data2[dimension][feature]).forEach(option => {
            data2[dimension][feature][option] =
              data2[dimension][feature][option] /
              (Object.keys(data1[dimension]).length - 1);
          });
        });
      });

      let data3 = {};
      let dimensionCount = 0;
      let dimensionAcc = 0;
      Object.keys(data2).forEach(dimension => {
        Object.keys(data2[dimension])
          .filter(feature => feature !== "satisfaction")
          .forEach(feature => {
            if (!data3[feature]) data3[feature] = {};
            Object.keys(data2[dimension][feature])
              .filter(key => key !== "satisfaction")
              .forEach(option => {
                if (!data3[feature][option])
                  data3[feature][option] = { acc: 0, total: 0 };
                dimensionAcc += parseInt(data2[dimension][feature][option]);
                dimensionCount += 1;
                data3[feature][option].acc += data2[dimension][feature][option];
                data3[feature][option].total += 1;
              });
          });
      });
      Object.keys(data3).forEach(feature => {
        Object.keys(data3[feature])
          .filter(key => key !== "satisfaction")
          .forEach(option => {
            data3[feature][option] =
              data3[feature][option].acc / data3[feature][option].total;
          });
      });
      data3.satisfaction = dimensionAcc / dimensionCount;
      this.generalReportData = [
        data1,
        data2,
        data3,
        participation,
        enps,
        enpsTotal
      ];
    }
  },

  computed: {
    ...mapState(["answers", "company"]),
    ...mapState(["survey", "surveys"]),

    ...mapState({
      selectedSurvey: state => {
        return {
          ...state.survey
        };
      }
    }),

    ...mapState({
      cat: state => {
        return [...state.filters.filters];
      },
      scale: state => {
        return [...state.scale.scale];
      }
    }),

    downloadLink() {
      let csvFileData = this.getReportCSV();
      var csvContent = "\uFEFF" + csvFileData;
      var blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
      var url = URL.createObjectURL(blob);
      return url;
    },

    dataStyles() {
      return {
        "--options-length": this.cat.reduce(
          (acc, val) => (acc += val.options.length),
          0
        ),
        "--category-length": this.cat.length
      };
    }
  },

  async mounted() {
    //await this.fetchAllAnswers();
    this.loading = true;
    try {
      await this.fetchAllSurveys(this.company.id);
      await this.setCurrentSurvey(
        this.surveys.find(
          survey => survey.name === this.$route.params.surveyName
        )
      );
      await this.fetchFilters();
      //await this.fetchScale();
    } catch (err) {
      console.log("error fetching");
    }
    //this.loading = false; //

    await this.getReportData();
    //this.getReport();
  }
};
</script>

<style>
.container {
  padding: 1em;
}

.container > *:first-child {
  margin-bottom: 1em;
}
.general-report-table {
  width: fit-content;
  color: black;
}

.general-report-table span {
  padding: var(--lengthSm3);
}

.header.row,
.option-value {
  letter-spacing: 1px;
  text-transform: uppercase;
}

.row {
  font-size: 0.8rem;
  font-weight: var(--medium);
  color: var(--fontColor3);
}

.content .dimension.row {
  display: grid;
  grid-template-columns: 200px auto;
}

.average.row,
.average.row .option-value span {
  color: black;
  background-color: var(--grayColor1);
}

.content .average.row .option-value {
  color: var(--grayColor1);
}

.content .dimension.row > span:first-child,
.content .question.row > span,
.content .average.row > span {
  display: flex;
  align-items: center;
}

.content .question.row > span:nth-child(2),
.content .average.row > span:nth-child(2) {
  justify-content: center;
}

.content .average.row > span,
.content .average.row > div,
.content .question.row > span,
.content .question.row > div,
.content .dimension.row > span:first-child {
  border-bottom: solid 1px var(--grayColor2);
  border-left: solid 1px var(--grayColor2);
}

.content .question.row > div:last-child,
.content .average.row > div:last-child {
  border-right: solid 1px var(--grayColor2);
}

.content .question.row,
.average.row {
  display: grid;
  grid-template-columns: 1000px 100px repeat(var(--category-length), auto);
}

.row .option {
  display: flex;
  gap: 1em;
}

.row .option .option-value {
  color: white;
  position: relative;
  user-select: none;
}

.option .option-value > span {
  color: var(--fontColor3);
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
  top: 0;
}

.header {
  display: grid;
  grid-template-columns: 200px 1000px 100px repeat(var(--options-length), auto);
  border-top-left-radius: var(--lengthSm2);
  border-top-right-radius: var(--lengthSm2);
  text-transform: uppercase;
  background-color: var(--grayColor1);
  font-weight: var(--medium);
}

.header-column-title {
  display: flex;
  justify-content: center;
  flex-flow: column;
  border: solid 1px var(--grayColor2);
  border-right: none;
}

.header-column-title:nth-child(3) {
  text-align: center;
}

.header-column-title:last-child {
  border-right: solid 1px var(--grayColor2);
  border-top-right-radius: var(--lengthSm2);
}

.header-column-title:first-child {
  border-top-left-radius: var(--lengthSm2);
}

.header-column-title > span:first-child {
  text-align: center;
  border-bottom: solid 1px var(--grayColor2);
}

.category-options {
  display: flex;
  gap: 1em;
}

.category-options > span {
  width: fit-content;
  white-space: nowrap;
}
</style>
